
import './About.css';
import Basic from '../Basic/basic';
import { useTypewriter, Cursor } from 'react-simple-typewriter'

function About() {
    const [text] = useTypewriter({
        words: ["OYBEK FATTAKHOV", "a Penetration Tester"],
        loop: true,
        typeSpeed: 20,
        delaySpeed: 2000,
        deleteSpeed: 10,
    })

    return (
        <div className='About'>
            <div className="info">
                <h1>About me</h1>
                <h2 > <span className='typing'>I'm</span> {text}
                    <Cursor
                        cursorBlinking='false'
                        cursorStyle='|'
                        cursorColor='#21ef00' />
                </h2>

                <p>I am a junior penetration tester, I bring extensive expertise in identifying and mitigating security
                    vulnerabilities. I have experience using a range of security tools and technologies, including
                    penetration testing tools, network analyzers, and vulnerability scanners. I am good at conducting
                    ethical hacking assessments, have a solid understanding of the MITRE ATT&CK framework, and am
                    skilled in developing customized training programs. I am committed to staying up-to-date with the
                    latest trends and techniques in cybersecurity, and am passionate about helping organizations
                    secure their networks, systems, and data from cyber threats</p>
            </div>
            <div className="data">
                <div className="data-w">
                    <data-l>
                        <p><span>Birthday:</span> 4 08 2002<span /></p>
                        <p> <span>Website:</span> <a href="https://www.penetrationtester.pages.dev">Penetration Tester</a> </p>
                        <p> <span>Degree:</span>Bachelor Degree</p>
                        <p> <span>Adress:</span>서울특별시 광진구 군자동
                            347-9  3층</p>
                    </data-l>

                    <div className="data-r">
                        <p><span>Age:</span> 21</p>
                        <p><span>Email:</span>oybekfattakhov07@gmail.com</p>
                        <p><span>Phone:</span>010-8460-3936
                        </p>
                        <p><span>Linkedln:</span> <a href="https://www.linkedin.com/in/oybek-fattakhov-559333261">oybek-fattakhov-559333261 </a> </p>
                    </div>

                </div>

            </div>
            <Basic />
            <div className="resume">
                <h1 className='title'>Experience</h1>

                <div className="experience">
                    <div className="hackbox">
                        <button></button>
                        <h1>Hackthe Box</h1>

                        <p>I possess experience using penetration testing tools such as Nmap, Metasploit, and Burp
                            Suite, and have demonstrated proficiency in applying these tools within the Hack The Box
                            platform.
                            As an active member of the Hack The Box community, I have gained hands-on experience
                            in identifying and exploiting vulnerabilities in systems and applications.
                            Through participation in various challenges and labs, I have developed a strong
                            understanding of network security and ethical hacking principles</p>
                    </div>

                    <div className="hackbox">
                        <button></button>
                        <h1>TryHackme</h1>

                        <p>I have gained a strong foundation in cybersecurity concepts and tools through my
                            completion of the Introduction to Cybersecurity and SOC Level 1 sections on the
                            TryHackMe platform.
                            These courses provided me with a comprehensive understanding of cybersecurity
                            principles, including network security, web security, cryptography, and more.
                            Currently, I am developing my expertise in offensive pentesting and defensive security
                            techniques through participation in the Offensive Pentesting and Defense sections on the
                            TryHackMe platform.
                        </p>
                    </div>


                    <div className="hackbox">
                        <button></button>
                        <h1>Skills</h1>

                        <div className="skpw">
                            <sk-pl>
                                <p>• Networking fundamentals</p>
                                <p>• Have a solid knowledge of Linux OS (Kali, Ubuntu, Debian) and Windows OS</p>
                                <p>• TCP/IP protocols</p>
                                <p>• OSI model and its all 7 layers</p>
                                <p>• Network scanning tools (Nmap, Masscan)</p>
                                <p>• Vulnerability scanning tools such as (Nessus, OpenVAS)TCP/IP protocols</p>
                                <p>• Exploitation frameworks such as (Metasploit, ExploitDB)</p>
                                <p>• SQL injection</p>
                                <p>• Intrusion detection and prevention systems (IDS/IPS)</p>
                            </sk-pl>
                            <div className="sk-pr">
                                <p>• Python scripting</p>
                                <p>• Web server testing (Apache, Nginx)</p>
                                <p>• Database (MySQL, Oracle)</p>
                                <p>• Threat modeling</p>
                                <p>• Risk assessment and management</p>
                                <p>• Penetration testing reporting and documentation</p>
                                <p>• Communication and presentation skills</p>
                                <p>• Teamwork and collaboration skills</p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default About