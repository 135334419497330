import React from 'react';
import './basic.css'
import {
    Radar, RadarChart, PolarGrid,
    PolarAngleAxis, PolarRadiusAxis
} from 'recharts';

const Basic = () => {

    // Sample data
    const data = [
        { name: 'Linux', x: 21, },
        { name: 'Networking', x: 7 },
        { name: 'SQL injection', x: -7 },
        { name: '', x: -51 },
        { name: 'Privilege Escalation', x: 16 },
        { name: 'Python scripting', x: 23 },
        { name: 'Database', x: 2 },
        { name: 'OSI Model', x: 0 },
    ];

    return (
        <div className="basic">
            <div className="bw"><RadarChart height={520} width={520}
                outerRadius="50%" data={data} >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <PolarRadiusAxis />
                <Radar dataKey="x" stroke="blue"
                    fill="#21ef00" fillOpacity={0.7} />
            </RadarChart></div>

        </div >

    );
}

export default Basic;
