import React from 'react'
import './navbar.css'
import Logost from '../../img/[removal.ai]_tmp-642d1fd745f9b.png'
function Navbar() {
    const downloadFile = () => {
        const url = 'http://localhost:3000/resume.pdf'; // replace with your file URL
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'resume.pdf'; // replace with your desired file name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    };

    return (
        <div className='Navbar'>
            <nav>
                <div className="logopage">
                    <img src={Logost} alt="" />
                    <h1>Richard</h1>
                </div>

                <ul>
                    <li>Home </li>
                    <li>About</li>
                    <li onClick={downloadFile}>Resume</li>
                    <li>Hacking</li>
                    <li>Certifications</li>
                    <li>Path</li>
                </ul>
            </nav>
            <div className="profil">

            </div>
        </div >
    )
}

export default Navbar