import React from 'react'
import './home.css'
// import Logokali from '../../../img/[removal.ai]_tmp-642d22571591d.png'
import Oynek from '../../img/[removal.ai]_tmp-642d1fd745f9b.png'

function Home() {
    return (
        <div className='Home' id='home'>
            <div className="wrapper">
                <div className="linux">
                    <h1>Penetration Tester</h1>
                    <img src={Oynek} alt="" />
                    <p>As a penetration tester, my job is to identify vulnerabilities in computer systems and networks using tools such as Nmap, Metasploit, Burp Suite, Wireshark. I have gained some experience on platforms like Hack The Box, which simulate real-world scenarios and kept up to date with the latest techniques.</p></div>


                {/* <div className="logoimg">
                    <div className="kali">
                        <img src={Logokali} alt="" />
                        Kali linux
                    </div>

                </div> */}
            </div>
            <div className="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

        </div>
    )
}

export default Home